import React from "react";

import { Seo } from "../Layout/Seo";
import { Header } from "./Header";
import { Block2 } from "./Block2";
import { Block3 } from "./Block3";
import { Block4 } from "./Block4";
import { Block5 } from "./Block5";
import { Block6 } from "./Block6";
import { Posts } from "../Posts";

const SendMoneyPage = ({ pageContext, location, lang }) => {
  const { page } = pageContext;

  const fragments = page;

  return (
    <Seo
      title={fragments.SeoTitle}
      description={fragments.SeoDescription}
      lang={lang}
    >
      <Header
        data={fragments.header}
        flagsPricingSendities={fragments.block7}
        location={location}
        country_code={pageContext.country_code}
        lang={lang}
      />
      <Block2 data={fragments.block2Home} />
      <Block3 data={fragments.block3Home} />
      <Block4 data={fragments.block4Home} />
      <Block5 data={fragments.block5Home} />
      {/* <Block6 data={fragments.block6} /> */}
      <Posts location={location} lang={lang} />
      {/* <SendMoneyPosts /> */}
    </Seo>
  );
};
export default SendMoneyPage;
