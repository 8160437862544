import React from "react";
import GatsbyImages from "../../GatsbyImages";
import "./block4.css";

const Block4 = ({ data }) => {
  const listComponent = data.ListTitleText.map((val, index) => {
    return (
      <li key={index}>
        <div className="como">
          <h3
            dangerouslySetInnerHTML={{
              __html: val.title,
            }}
          ></h3>
          <p
            dangerouslySetInnerHTML={{
              __html: val.text,
            }}
          ></p>
        </div>
      </li>
    );
  });

  const imgComp = (
    <GatsbyImages
      alt={data.image.alternativeText}
      src={data.image.localFile.childImageSharp}
      ext={data.image.ext}
      height={653}
      width={482}
    />
  );

  const h2comp = (
    <h2
      dangerouslySetInnerHTML={{
        __html: data.title,
      }}
    ></h2>
  );

  return (
    <div className="options">
      <div className="container">
        <div className="options-in">
          {h2comp}
          <div className="multiple-opt d-flex flex-wrap">
            <div className="list-opt-main col-xl-7 col-lg-8 col-12">
              <div className="list-opt d-flex">
                <div className="lft-opt">
                  <figure></figure>
                </div>
                <div className="rgt-opt">
                  <ul className="clearfix">{listComponent}</ul>
                </div>
                <div className="clear"></div>
              </div>
            </div>
            <div className="girl col-xl-5 col-lg-4 col-12">{imgComp}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Block4 };
