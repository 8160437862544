import React from "react";
import { MoneyConvertor } from "../../MoneyConvertor";
import wave2 from "../../../images/wave2.svg";
const Header = ({
  flagsPricingSendities,
  location,
  country_code,
  lang,
  data,
}) => {
  return (
    <>
      <div className="bannerSendMoney">
        <div className="container">
          <div className="d-flex flex-wrap justify-content-center">
            <MoneyConvertor
              locationName="remesas"
              headerTextData={data.title}
              location={location.href}
              country_code={country_code}
              flagsPricingSendities={flagsPricingSendities}
              lang={lang}
            />
          </div>
        </div>
      </div>
      <div className="wave-container">
        <img loading="eager" src={wave2} alt="Wave2" className="wave" />
      </div>
    </>
  );
};

export { Header };
